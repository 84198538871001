<template>
  <div>
    <ConfirmDialog
      title="Dodaj ilustrację"
      :value.sync="show"
      persistend
      positiveButtonText="Dodaj"
      :positiveAction="downloadBackground"
      :loading="loading"
      dontHideOnPositiveAction
      :disabledPositiveButton="!selectedBackground || loading"
    >
      <v-layout column align-center slot="content">
        <DefaultSelect
          :items="backgrounds"
          :itemText="(item) => item.name"
          :itemValue="(item) => item.id"
          :value.sync="selectedBackground"
          style="width: 100%"
          label="Grafika"
          placeholder="Wybierz grafikę.."
          horizontal
          returnObject
        ></DefaultSelect>
      </v-layout>
    </ConfirmDialog>
    <input
      ref="file"
      type="file"
      style="display: none"
      @change="handleSelectedFile"
    />
    <EditImagePopup
      v-if="showEditImagePopup"
      :showPopup="showEditImagePopup"
      :value="file"
      @saveEditedImage="handleEditedFile"
    >
    </EditImagePopup>
  </div>
</template>

<script>
import DrawingsService from "@/services/drawings.service";
import CRUDMixin from "@/mixins/CRUDMixin";
import { IMAGE } from "@/components/ImageWithDescription";
import FileHelper from "@/services/file.helper";

export default {
  mixins: [CRUDMixin],
  props: {
    value: {},
  },
  data() {
    return {
      showEditImagePopup: false,
      selectedBackground: null,
      backgrounds: [],
      file: null,
    };
  },
  watch: {
    selectedBackground() {
      if (this.selectedBackground.id === "select") {
        this.$refs.file.click();
      } else {
        this.showEditImagePopup = false;
        this.file = null;
        this.downloadBackground();
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  components: {
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    DefaultSelect: () => import("@/components/selects/DefaultSelect"),
    EditImagePopup: () => import("@/components/popups/EditImagePopup"),
  },
  methods: {
    fetch() {
      this.beforeRequest();
      DrawingsService.getBackgrounds()
        .then((reply) => {
          this.backgrounds = reply;
          this.backgrounds.push({ divider: true });
          this.backgrounds.push({
            id: "select",
            name: "Własna ilustracja",
          });
          this.reset();
        })
        .catch((error) => {
          console.log("ERROR", error, error.response);
          this.handleError("Coś poszło nie tak.");
        });
    },
    downloadBackground() {
      if (!this.file && this.selectedBackground.id === "select") {
        this.$refs.file.click();
        return;
      }
      this.beforeRequest();
      DrawingsService.downloadPicture(
        process.env.VUE_APP_URL_BACKEND + this.selectedBackground.file
      )
        .then((reply) => {
          let blob = new Blob([reply], { type: "image/svg+xml" });
          this.$emit("selected", blob, this.selectedBackground.name);
          this.reset();
          this.show = false;
        })
        .catch((error) => {
          console.log("ERROR", error, error.response);
          this.handleError("Coś poszło nie tak.");
        });
    },
    handleEditedFile() {
      fetch(this.file.image)
        .then((res) => res.blob())
        .then((blob) => {
          let newFile = blob;
          newFile.name = this.file.title;
          this.$emit("selected", newFile, "Własna ilustracja");
          this.show = false;
        });
    },
    async handleSelectedFile(e) {
      const file = e.target.files[0];
      if (file.type !== "image/svg+xml") {
        this.file = await this.prepareFile(file).then((base64) => {
          const size = Math.floor(base64.length * (3 / 4) - 2);
          return {
            image: base64,
            title: file.name,
            human_size: FileHelper.humanSize(size, true),
            size: size,
            type: IMAGE,
          };
        });
        this.showEditImagePopup = true;
      } else {
        this.$emit("selected", file, "Własna ilustracja");
        this.show = false;
      }
    },
    async prepareFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          FileHelper.resizeImage({ file: file }).then((resizedImage) => {
            resolve(resizedImage);
          });
        };
        reader.onerror = (error) => reject(error);
      });
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>